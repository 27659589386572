import { Center, Loader, Table } from "@mantine/core";
import * as React  from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFlights } from "../../services/skyway/AirLabsFlightService";
import { Airport } from "../../services/skyway/ISkywayFlightService";
import { setSelectedFlight, SkywayState } from "../../store/skywaySlice";
import { RootState } from "../../store/store";

import "./flightList.scss";

type Props = {
    from: Airport
    to: Airport
}
export const FlightList : React.FC<Props> = ({from, to}) => {

    const dispatch = useDispatch();
    const { selectedFlight } = useSelector<RootState, SkywayState>(x =>x.skyway);

    const { flights, isLoading } = useFlights(from, to);
    React.useEffect(() => {
        if(flights == null || flights.length == 0) {
            dispatch(setSelectedFlight({flight: undefined }));
            return;
        }

        dispatch(setSelectedFlight({flight: flights[0] }));
    }, [flights]);

    const rows = flights.map((flight, index) => (
        <tr key={flight.id} 
            className={flight.id == selectedFlight?.id ? "flight--selected" : "flight"}
            onClick={(event) => {
                event.preventDefault();
                dispatch(setSelectedFlight({flight: flights[index] }));
            }}
        >
          <td>{flight.airlineID} - {flight.flightNumber}</td>
          <td>{flight.status}</td>
        </tr>
      ));
    
      if(isLoading) {
        return <>
          <Center>
            <Loader style={{margin: "2rem"}} color={"#9b61ba"} />
          </Center>
        </>;
      }

      return (
        <Table>
          <thead>
            <tr>
              <th>Flight Number</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      );
}