import { Environment, Float, OrbitControls, PerspectiveCamera, Stars, useHelper } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import * as React from "react";
import { useSelector } from "react-redux";
import { Search } from "../../components/skyway/Search";
import { Rotate } from "../../components/three/common/Rotate";
import { AxesHelper } from "../../components/three/helpers/AxesHelper";
import { Earth } from "../../components/three/skyway/earth";
import { EarthArc } from "../../components/three/skyway/earthArc";
import { EarthPoint } from "../../components/three/skyway/earthPoint";
import { useWindowSize } from "../../hooks/useWindowSize";
import { LatLng } from "../../services/models/LatLng";
import { SkywayState } from "../../store/skywaySlice";
import { RootState } from "../../store/store";
import { Accordion, Collapse, Divider, MantineProvider, ScrollArea, Space, Title } from '@mantine/core';

import "./index.scss";
import SEO from "../../components/layout/seo";
import { FlightList } from "../../components/skyway/FlightList";
import { Plane } from "../../components/three/skyway/plane";
import { useWebGLCheck } from "../../hooks/useWebGLCheck";

export const SkywayPage: React.FC = () => {
    const { width: windowWidth, height: windowHeight } = useWindowSize();

    const sphereRadius = 512;

    const { arrivalAirport, departureAirport, selectedFlight } = useSelector<RootState, SkywayState>(x => x.skyway);

    const arrivalLatLng = React.useMemo(() => new LatLng({ lat: arrivalAirport?.lat ?? 0, lng: arrivalAirport?.lng ?? 0 }), [arrivalAirport?.id]);
    const departureLatLng = React.useMemo(() => new LatLng({ lat: departureAirport?.lat ?? 0, lng: departureAirport?.lng ?? 0 }), [departureAirport?.id]);

    //WebGL check
    const { modal, isSupported } = useWebGLCheck();

    if (!isSupported) {
        return <>{modal}</>;
    }
    return (
        <div className="skyways">

            <SEO
                title="Skyways"
                image="/images/seo/skyways.png"
                description="Life is a Skyway. View global active flights on the globe."
            />

            <MantineProvider
                theme={{ colorScheme: "dark", fontFamily: "Varela Round" }}
            >
                <div className="searchContainer">
                    <ScrollArea type="scroll">
                        <Accordion defaultValue="Search" >
                            <Accordion.Item value="Search">
                                <Accordion.Control>Search</Accordion.Control>
                                <Accordion.Panel>
                                    {
                                        /*
                                        * This looks dumb and it is.
                                        * But it's in here because on page refreshes, width and height style props don't update.
                                        * Making the whole canvas blank.
                                        * This for some reason tells dumbo React to update the view.
                                        * It only happens in production, after refreshing the page.
                                        */
                                    }
                                    <div style={{display: "none"}}>
                                        {windowHeight}
                                        {windowWidth}
                                    </div>
                                    <Search />
                                    {
                                        arrivalAirport != null
                                        && departureAirport != null
                                        &&
                                        <Accordion defaultValue="FlightList">
                                            <Accordion.Item value="FlightList">
                                                <Accordion.Control>Flights</Accordion.Control>
                                                <Accordion.Panel>
                                                    <div style={{ marginTop: "1rem" }}>
                                                        <Divider size={"lg"} />
                                                        <FlightList from={departureAirport} to={arrivalAirport} />
                                                    </div>
                                                </Accordion.Panel>
                                            </Accordion.Item>
                                        </Accordion>

                                    }
                                </Accordion.Panel>
                            </Accordion.Item>
                        </Accordion>
                    </ScrollArea>
                </div>
            </MantineProvider>

            <div className="earthContainer" style={{ width: windowWidth, height: windowHeight }}>
                
                <Canvas>

                    <OrbitControls minDistance={800} maxDistance={4000} target={[0, 0, 0]} />
                    <PerspectiveCamera makeDefault position-z={1400} far={20000} />

                    <Rotate rotationSpeed={-0.001}>
                        <Earth radius={sphereRadius} />

                        {arrivalAirport != null
                            && <EarthPoint colour="#6e92cc" position={arrivalLatLng.projectOnSphere(sphereRadius)} />
                        }
                        {departureAirport != null
                            && <EarthPoint colour="#72cc9e" position={departureLatLng.projectOnSphere(sphereRadius)} />
                        }

                        {/* {
                                arrivalAirport != null 
                                && departureAirport != null
                                && <EarthArc radius={sphereRadius} start={arrivalLatLng} end={departureLatLng} />
                            } */}

                        {
                            selectedFlight != null
                            && <Plane position={new LatLng({ lat: selectedFlight.lat, lng: selectedFlight.lng }).projectOnSphere(sphereRadius + 16 + selectedFlight.altitude * 0.001)} />

                        }
                    </Rotate>

                    <ambientLight />

                    <Stars count={1000} radius={1200} />

                </Canvas>
            </div>

        </div>
    );
};

export default SkywayPage;