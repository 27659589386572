import React from "react";
import { GraphQLService } from "../GraphQLService";
import { Airport, City, Flight, ISkywayFlightService } from "./ISkywayFlightService";


export const useCities = () => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [cities, setCities] = React.useState<City[]>();
    React.useMemo(async () => {
        try{
            setIsLoading(true);
            const service = new AirLabsFlightService();
            setCities((await service.getCities()));
        }finally{
            setIsLoading(false);
        }
    },[]);
    return { isLoading, cities: cities ?? [] };
};

export const useAllAirports = () => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [airports, setAirports] = React.useState<Airport[]>();
    React.useMemo(async () => {
        try{
            setIsLoading(true);
            const service = new AirLabsFlightService();
            setAirports((await service.getAllAirports()));
        }finally{
            setIsLoading(false);
        }
    },[]);
    return { isLoading, airports: airports ?? [] };
};

export const useFlights = (from: Airport, to: Airport) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [flights, setFlights] = React.useState<Flight[]>();
    React.useMemo(async () => {
        try{
            setIsLoading(true);
            const service = new AirLabsFlightService();
            setFlights((await service.getFlights(from, to)));
        }finally{
            setIsLoading(false);
        }
    },[from, to]);
    return { isLoading, flights: flights ?? []};
};

export class AirLabsFlightService implements ISkywayFlightService {
    
    graphqlService: GraphQLService
    cache: any

    constructor() {
        this.graphqlService = new GraphQLService();
        this.cache = {} as any;
    }

    async getCities() {
        const data = await this.graphqlService.query<{cities: City[]}>({
            endpoint: "https://icecapp.ca/.netlify/functions/graphql",
            query: `
                query Cities {
                    cities {
                        id,
                        name
                    }
                }
            `,
            variables: {
            }}
        );
        return data?.cities;
    }

    async getFlights(from: Airport, to: Airport) {
        const data = await this.graphqlService.query<{airports: Array<{flights: Flight[]}> }>({
            endpoint: "https://icecapp.ca/.netlify/functions/graphql",
            query: `
                query Flights ($arrivalAirportID: ID, $departureAirportID: String!) {
                    airports(id: $arrivalAirportID) {
                        flights(to: $departureAirportID) {
                            id
                            flightNumber
                            status
                            airlineID
                            lat
                            lng
                            altitude
                        }
                    }
                }
            `,
            variables: {
                arrivalAirportID: from.id,
                departureAirportID: to.id,
            }}
        );

        if(data?.airports == null) return [];
        return data?.airports[0].flights;
    }

    async getAllAirports(){
        const data = await this.graphqlService.query<{airports: Airport[]}>({
            endpoint: "https://icecapp.ca/.netlify/functions/graphql",
            query: `
                query AllAirports {
                    airports {
                        id,
                        name,
                        lat,
                        lng
                    }
                }
            `,
            variables: {
            }}
        );
        return data?.airports;
    }
    
    async getAirports(city: City) {
        const data = await this.graphqlService.query<{cities: City[] & { airports: Airport[]}}>({
            endpoint: "https://icecapp.ca/.netlify/functions/graphql",
            query: `
                query CityAirports {
                    cities(id: $id) {
                        id,
                        name,
                        airports {
                            id,
                            name,
                            lat,
                            lng
                        }
                    }
                }
            `,
            variables: {
                id: city.id
            }}
        );
        return data?.cities?.airports;
    }

}