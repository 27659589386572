import { MeshProps } from "@react-three/fiber";
import * as React from "react"; 

type Props = {

} & MeshProps;
export const Plane : React.FC<Props> = (props) => {
    return (
        <mesh {...props}>
            <sphereBufferGeometry args={[16,16]} />
            <meshBasicMaterial color={"#9b61ba"} />
        </mesh>
    );
}