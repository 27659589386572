import { GroupProps, useFrame } from "@react-three/fiber";
import * as React from "react";

type Props = {
    rotationSpeed: number
}

export const Rotate : React.FC<Props> = ({children, rotationSpeed}) => {

    const groupRef  = React.useRef<GroupProps>();

    useFrame(() => {
        groupRef.current?.rotateY(rotationSpeed);
    });

    return (
        <group ref={groupRef as any}>
            {children}
        </group>
    )
}