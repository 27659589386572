import { MeshProps, useFrame, useLoader, useThree } from "@react-three/fiber";
import * as React from "react";
import { MathUtils, ShaderLib, ShaderMaterialParameters, Texture, TextureLoader, UniformsLib, UniformsUtils, Vector3 } from "three";
import vertexShader from "../../../shaders/vertex/earth.glsl";
import fragmentShader from "../../../shaders/fragment/earth.glsl";
import greyscaleImage from "../../../shaders/textures/earthgrayscale.jpg";
import dayImage from "../../../shaders/textures/earthday.png";
import nightImage from "../../../shaders/textures/earthnight.png";
import { Loader } from "../common/Loader";

const builtinShader = ShaderLib.standard;
let uniforms = UniformsUtils.merge([builtinShader.uniforms, UniformsLib.lights, UniformsLib.common]);
uniforms.time = { value: 0 };
uniforms.lookatVector =  { value: new Vector3(0,0,0) };
uniforms.earthHeightMap = { value: new Texture() };
uniforms.earthDayMap = { value: new Texture() };
uniforms.earthNightMap = { value: new Texture() };



//Component:

type Props = {
    radius: number
};

export const Earth : React.FC<Props> = (props) => {
    return (
        <React.Suspense fallback={<Loader shape="sphere" size={props.radius} />}>
            <EarthComponent {...props} />
        </React.Suspense>
    );
}

const EarthComponent : React.FC<Props> = ({radius}) => {

    const { camera } = useThree();
    const planetRef = React.useRef<MeshProps>();
    const materialRef = React.useRef<ShaderMaterialParameters & typeof uniforms>();
    const heightmap = useLoader(TextureLoader, greyscaleImage);
    const dayMap = useLoader(TextureLoader, dayImage);
    const nightMap = useLoader(TextureLoader, nightImage);


    useFrame((state) => {
        if(materialRef?.current?.uniforms == null) return;
        const vec = new Vector3();
        state.camera.getWorldDirection(vec);
        materialRef.current.uniforms.lookatVector.value = vec;
        materialRef.current.uniforms.time.value = state.clock.elapsedTime;
        materialRef.current.uniforms.earthHeightMap.value = heightmap;
        materialRef.current.uniforms.earthDayMap.value = dayMap;
        materialRef.current.uniforms.earthNightMap.value = nightMap;
    });
    
    return (
        <>
            <mesh ref={planetRef as any} rotation={[0,0,0]}>
                {/* <planeBufferGeometry args={[1024, 1024, 1024, 1024]} /> */}
                <sphereBufferGeometry args={[radius, 1024, 1024]} />
                <shaderMaterial
                    ref={materialRef} 
                    attach="material"
                    uniforms={uniforms}
                    vertexShader={vertexShader}
                    fragmentShader={fragmentShader}
                />
            </mesh>
        </>
    );
};