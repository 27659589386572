import * as React from "react";
import {
    Formik,
    FormikHelpers,
    FormikProps,
    Form,
    Field,
    FieldProps,
    useFormik
  } from 'formik';

import "./search.scss";
import { graphql, useStaticQuery } from "gatsby";
import { useDispatch, useSelector } from "react-redux";
import { setArrivalAirport, setDepartureAirport, SkywayState } from "../../store/skywaySlice";
import { Airport } from "../../services/skyway/ISkywayFlightService";
import { Autocomplete, Button, Center, ColorSwatch, Container, Grid, SimpleGrid, Stack } from "@mantine/core";
import { RootState } from "../../store/store";

export const Search : React.FC = () => {
    return (
        <section className="search">
            <SearchForm />
        </section>
    );
};

type InitialValues = {
    arrivalAirport?: Airport
    departureAirport?: Airport
};
const SearchForm : React.FC = () => {
    const dispatch = useDispatch();

    const { arrivalAirport, departureAirport } = useSelector<RootState, SkywayState>(x => x.skyway);
  
    const formik = useFormik<InitialValues>({
        initialValues: {
        },
        onSubmit: values => {
        },
    });

    const getAirportAutocompleteValue = (x?: Airport) => x == null ? "" : `${x.id} - ${x.name}`;

    const airports = (useStaticQuery(graphql`
        query AllAirports {
            icecapp {
                airports {
                    id,
                    name,
                    lat,
                    lng
                }
            }
        }
    `) as Queries.AllAirportsQuery).icecapp.airports
    ?.map((x) => ({
        ...x,
        value: getAirportAutocompleteValue(x as Airport)
    })) ?? [];



    return (
        <form onSubmit={formik.handleSubmit}>
            
            <Center>
                <Stack>
                    <Grid justify={"center"} align="flex-end">
                        <Grid.Col span={10}>
                            <Autocomplete
                                data={airports}
                                defaultValue={getAirportAutocompleteValue(departureAirport)}
                                onItemSubmit={(airport) => {
                                    formik.setFieldValue("departureAirport", airport?.id);
                                    dispatch(setDepartureAirport({airport: airport as unknown as Airport}));
                                }}           
                                label="Where are you coming from ?"
                                placeholder="Departure City"
                            />
                        </Grid.Col>
                        <Grid.Col span={2}>
                            <ColorSwatch color="#72cc9e" />
                        </Grid.Col>
                    </Grid>
                    <Grid justify={"center"} align="flex-end">
                        <Grid.Col span={10}>
                            <Autocomplete
                                data={airports}
                                defaultValue={getAirportAutocompleteValue(arrivalAirport)}
                                onItemSubmit={(airport) => {
                                    formik.setFieldValue("arrivalAirport", airport?.id);
                                    dispatch(setArrivalAirport({airport: airport as unknown as Airport}));
                                }}           
                                label="Where are you going ?"
                                placeholder="Arrival City"
                            />
                        </Grid.Col>
                        <Grid.Col span={2}>
                            <ColorSwatch color="#6e92cc" />
                        </Grid.Col>
                    </Grid>
                </Stack>
            </Center>


        </form>

    );
}