export class GraphQLService {
    query = async <T>(config: { 
        endpoint: string,
        query: string,
        variables?: object
    }): Promise<T> => {
        const response = await fetch(config.endpoint, {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query: config.query,
                variables: config.variables ?? {}
            })
        })
        const resultData = await response.json();
        return resultData?.data as T ?? resultData as T;
    }
}