import { Spherical, Vector3 } from "three";

export class LatLng {

    lat: number; //y (north, south) [90,-90]
    lng: number; //x (east,west) [-180, 180]

    constructor({ lat, lng } : {lat: number, lng: number}) {
        this.lat = lat;
        this.lng = lng;
    }


    //Converts lat,lng in SphereCoordinateSystem to CartesianCoordinateSystem of ThreeJS
    //This is a good explaination: https://rbrundritt.wordpress.com/2008/10/14/conversion-between-spherical-and-cartesian-coordinates-systems/
    //Code from this StackoverFlow answer: https://stackoverflow.com/questions/28365948/javascript-latitude-longitude-to-xyz-position-on-earth-threejs
    //Seems to do the math from the above, but changes lat,lng origin and flips x for ThreeJS specific coordinate space changes.
    projectOnSphere(radius: number) {

        //Lat is [-90,90]. This changes range to [-180, 0].
        const phi = (90 - this.lat) * (Math.PI/180);

        //Lng is [-180,180]. This changes range to [0,360].
        const theta = (this.lng + 180) * (Math.PI/180);

        const x = -(radius * Math.sin(phi)*Math.cos(theta));
        const z = (radius * Math.sin(phi)*Math.sin(theta));
        const y = (radius * Math.cos(phi));

        return new Vector3(x,y,z);
    }
}