import { BoxBufferGeometryProps, BoxGeometryProps, MeshProps, SphereBufferGeometryProps, TorusBufferGeometryProps, useFrame } from "@react-three/fiber";
import * as React from "react";

type ConfigProps = SphereBufferGeometryProps | TorusBufferGeometryProps | BoxBufferGeometryProps;
type Props = {
    size?: number
    shape: "sphere" | "torus" | "box"
    spin?: boolean
    config?: ConfigProps
}

const shapes = {
    sphere: (size: number, config: ConfigProps) => <sphereBufferGeometry args={[size, 8, 8]} {...config as SphereBufferGeometryProps} />,
    torus: (size: number, config: ConfigProps) => <torusBufferGeometry args={[size, 10, 16, 20]} {...config as TorusBufferGeometryProps} />,
    box: (size: number, config: ConfigProps) => <boxBufferGeometry args={[size, size, size]} {...config as BoxBufferGeometryProps} />,
}
export const Loader : React.FC<Props> = ({size = 30, shape, config = {}, spin = true}) => {

    const shapeRef = React.useRef<MeshProps>();

    useFrame(() => {
        if(!spin) return;
        if(shapeRef?.current == null) return;
    });

    const shapeGeometry = shapes[shape](size, config);

    return (
        <group>
            <mesh ref={shapeRef as any}>
                {shapeGeometry}
                <meshBasicMaterial wireframe color={"white"} />
            </mesh>
        </group>
       
    );
}