import { Color, MeshProps } from "@react-three/fiber";
import * as React from "react";

type Props = {
    colour: Color
} & MeshProps;
export const EarthPoint : React.FC<Props> = (props) => {
    return (
        <mesh {...props}>
            <sphereBufferGeometry args={[32,128,128]} />
            <meshPhongMaterial transparent={true} opacity={0.5} color={props.colour} />
        </mesh>
    );
}